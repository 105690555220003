
import question from './quiz.vue';
import results from './results.vue';
import Vue from 'vue/dist/vue.js'
import questions from './questions.json.js';
import './index.scss';




const app = new Vue({el:'#question',
	methods: {
		newQuestion: function(answerNumber){
			this.score += this.question.scores[answerNumber]
			this.currentQuestion++;
			if(this.currentQuestion>=questions.length) {
				this.questioning=false;
				if(this.score>0){
					this.worthy=true;
				}
			} else {
				this.question = questions[this.currentQuestion];
			}
	
		}},
	template: `<div id='question'>
			<question v-bind:question="question" v-if="questioning" :on-click="newQuestion"></question>
			<results v-bind:worthy="worthy"v-if="!questioning" />
		</div>`,
	data:{question: questions[0],
		score:0,
		questioning:true,
		currentQuestion: 0,
		worthy: false},
	components:{question,
		results}} )

console.log('hello world');