<template>
<div class='quiz-results'>
		<div v-if="worthy" class='quiz-results__result quiz-results--worthy'><em class='quiz-results__em'>YES</em> <p class='quiz-results__message'>Do eat at our chicken shack.</p></div>
		<div v-if="!worthy" class='quiz-results__result quiz-results--unworthy'><em class='quiz-results__em'>NO</em> <p class='quiz-results__message'>Don't eat at our chicken shack.</p></div>
</div>
</template>

<script>
		export default {name:'results',
			props:['worthy'] }
</script>

<style lang='scss'>

	.quiz-results {
		margin:0 auto;
		max-width:800px;

		&__result {
			font-family: chuck, sans-serif;

			font-size:40px;
			z-index:4;
			position:relative;
			color:white;
			letter-spacing: 2px;
	    text-shadow: #a0602c 1px 1px 2px;
	    margin-top:40px;

  	}

    &__em {
    	font-family: flood-std, sans-serif;
    	color:red;
    	display:block;
    	font-size:2em;
    	text-align:center;
    }

    &__message {
    	padding-left:16px;
    }
	}

	.quiz-results--worthy {
		.quiz-results__em {
			text-shadow: none;
    	color:#22EEff;
		}
	}


	@media (min-width:1024px) {
		.quiz-results__result {
			font-size:60px;
		}
	}

</style>