

const yesNo = ['Yes', 'No'];

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export default shuffle([
	{question: 'Are you fragile?',
		scores: [-1, 1],
		answers: yesNo},
	
	{question: 'Do you consensually have sex with whoever you want with no worries',
		scores: [1, 0],
		answers: yesNo},

	{question: 'Do you personally believe a rape victim has to keep their baby, especially because "god wanted her to get pregnant?"',
		scores: [-8, 1],
		answers: yesNo},

	{question: 'How do you pronounce "gif"',
		scores: [1, 1],
		answers: ['gif','gif']},

	{question: 'Is Kobe better than LeBron?',
		scores: [-3, 0],
		answers: yesNo},

	{question: 'Are you annoyed about our Chicken Shack?',
		scores: [-3, 1],
		answers: yesNo},

	{question: 'Are Grape Swishers better than Standard Swishers?',
		scores: [-2, 1],
		answers: yesNo},

	{question: 'Would you shit on your sister in law for 20 grand?',
		scores: [0, -1],
		answers: yesNo},

	{question: 'Do you have the balls to hock a loogie on your wife\'s vagina in public?',
		scores: [1, -2],
		answers: yesNo},

	{question: 'Have you ever got Rosenberged?',
		scores: [1, 0],
		answers: yesNo},

	{question: 'Have you ever ate a whole gummy your dishwasher gave you?',
		scores: [1, 0],
		answers: yesNo},

	{question: 'Do you smoke mids like Ed Lee?',
		scores: [-1, 0],
		answers: yesNo},

	{question: 'Who do you pick on Mario Kart?',
		scores: [0, -3, 0, 0],
		answers: ['Mario', 'Yoshi', 'Bowser', 'Peach']},

	{question: 'Your partner leads spades - what do you play?',
		scores: [-1, 0, 0],
		answers: ['lowest Card', 'highest card', 'neither']},

	{question: 'Do you you play deuce high spades?',
		scores: [1, 0],
		answers: yesNo},

	{question: 'What do you think of Lupes newer stuff?',
		scores: [-2, 0, 0],
		answers: ['slaps','who?','meh']},

	{question: 'Have you ever took your ex back so you could cheat on them because they cheated on you?',
		scores: [1, 0, 0],
		answers: [ "Yes", "No", "I don't live in Louisville"]},
	
	{question: 'Have you ever shared posts from "The Bitchy Server"?',
		scores: [-1, 0],
		answers: yesNo},

	{question: 'Do you ever play Devils Advocate on the internet?',
		scores: [-3, 0],
		answers: yesNo},

	{question: 'What is your favorite OutKast album?',
		scores: [ 0, 0, -1, 0, 0],
		answers: ['ATLiens', 'Aquemini',  'Stankonia', 'Speakerboxxx', 'Idlewild']},
	
	{question: 'Do you listen to Jack Harlow?',
		scores: [1, 0],
		answers: yesNo},

	{question: 'Ultimate frisbee?',
		scores: [-2, 0],
		answers: ["Anytime anywhere", "Fuck off"]},

	{question: 'Do you listen to Jadakiss?',
		scores: [1, 0],
		answers: yesNo},

	{question: 'Do you like The Eagle?',
		scores: [-3, 0],
		answers: yesNo},

	{question: 'Is Culver\'s the superior fast food?',
		scores: [1, 0],
		answers: yesNo},

	{question: 'Do you listen to Iggy Azalea?',
		scores: [-1, 0],
		answers: yesNo},
		//If you have a Blue Lives Matter flag in your front yard go eat at The Eagle
	
]);

