<template>
<div class='quiz-question'>
		<h2 class='quiz-question__question'>{{question.question}}</h2>
		<ul class='quiz-question__answer-list' >
			<li v-for="(answer, index) in question.answers" class="quiz-question-answer" v-on:click="onClick(index)">
				<div class='quiz-question-answer__answer-container'>{{answer}}</div>
			</li>
		</ul>
</div>
</template>

<script>
		export default {name:'question',
			props:['question', 'on-click'] }
</script>

<style lang='scss'>

	.quiz-question {
		padding-right:20px;
		padding-left:20px;
		max-width:700px;
		margin:0 auto;
		font-size:16px;
		text-align:center;
		padding-right:10px;
	}

		@media all and (min-width:1200px) {
			.quiz-question {
				padding-right:100px;
			}
		}

	.quiz-question__question {
		font-family: chuck, sans-serif;
		font-family: flood-std, sans-serif;
		font-family: chantal, sans-serif;
		color:white;
		letter-spacing: 2px;
    text-shadow: #a0602c 1px 1px 2px;
    animation: floating 10s ease-in-out infinite;
    margin-top:40px;

    em {
    	color:red;
    }
	}

	.quiz-question__answer-list{
		padding:0;
		margin-top:20px;
		list-style:none;
		padding-bottom:50px;
	}

	.quiz-question-answer{
		text-align:center;
		margin-bottom:8px;
		list-style:none;
		-webkit-perspective: 500;
		font-size:15px;
		&:hover {
			cursor:pointer;
		}

		&:hover .quiz-question-answer__answer-container{
			cursor:pointer;
			
			animation: balance 1.5s ease-in-out 1 alternate;
		}
	}



	$edgeSize:20px;
	$height:40px;
	.quiz-question-answer__answer-container {
		display:inline-block;
		padding-right:20px;
		padding-left:8px;
		height:$height;
		line-height:$height;

		transition:transform 2s ease;
		transform-origin: top;

		margin:0;
		text-indent:0;
		background: transparent url('img/plank-center.svg');
		background-size:auto $height;
		background-repeat: no-repeat;
		background-position:right center;
		position:relative;
		color:#8470a8;
		font-family: chantal, sans-serif;
		// font-family: flood-std, sans-serif;

		font-weight: 600;

		&:before{
			content:"";
			height:$height;
			width:$edgeSize;
			position:absolute;
			top:0;
			display:block;
			background:transparent url('img/plank.svg');
			background-size: auto $height;
			background-repeat:none;
			background-position:left center;
			left: 2px - $edgeSize;
		}

	}

@media (min-width:1024px) {
	.quiz-question__question {
		font-size:36px;
		margin-bottom:40px;
		
	}

	
	$height:60px;
	.quiz-question-answer {
		margin-bottom:20px;
	}
	.quiz-question-answer__answer-container {
		height:$height;
		line-height:$height;
		background-size:auto $height;
		font-size:30px;
		padding:0 40px;
		padding-right:60px;
		&:before{
			height:$height;
			background-size: auto $height;
		}
	}
}


$floatSize:4px;
@-webkit-keyframes "floating" {
	0% {
		-webkit-transform: translateY($floatSize);
		transform: translateY($floatSize);
	}


	50% {
		-webkit-transform: translateY(-$floatSize);
		transform: translateY(-$floatSize);
	}

	100% {
		-webkit-transform: translateY($floatSize);
		transform: translateY($floatSize);
	}

}


@-webkit-keyframes "balance" {
0% {

-webkit-transform: rotateX(40deg);
transform: rotateX(40deg);
}

15% {

-webkit-transform: rotateX(-30deg);
transform: rotateX(-30deg);
}
30% {

-webkit-transform: rotateX(25deg);
transform: rotateX(30deg);
}
45% {

-webkit-transform: rotateX(-20deg);
transform: rotateX(-10deg);
}
60% {

-webkit-transform: rotateX(10deg);
transform: rotateX(10deg);
}
75% {

-webkit-transform: rotateX(-5deg);
transform: rotateX(-5deg);
}
100% {
-webkit-transform: rotateX(0deg);
transform: rotateX(0deg);
}

}
</style>